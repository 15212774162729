.login {
  padding: 20px;

  h3 {
    padding-top: 40px;
    text-align: center;
    font-size: 2.4rem;
  }
}

@primary-color: #1DA57A;