.detail {

  .user {
    position: relative;

    .description {
      span {
        margin-right: .4rem;
      }
    }

    .extra {
      position: absolute;
      right: .5rem;
      top: .5rem;

      button {
        margin-left: .5rem;
      }
    }

    .mobile {
      color: #888;
      margin-left: .6rem;
    }

    :global {
      .adm-list-card {
        border: 1px solid #eee;
      }
    }
  }

  :global {

    .adm-card-header {
      border-bottom: 1px solid #eee;
      .adm-card-header-title {
        font-size: 1.6rem;
      }
    }

  }
}

@primary-color: #1DA57A;