.progress {
  :global {
    .adm-card-header {
      border-bottom: 0;
    }
  }


  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      display: flex;
      padding-bottom: 1rem;

      .label {
        width: 5rem;
        text-align: center;
        font-weight: 500;

      }

      .chapterWrap {
        flex: 1;

        .chapter {
          opacity: .8;
        }

        .num {
          padding-top: .5rem;
          font-weight: 500;
        }
      }
    }
  }
}

@primary-color: #1DA57A;