.charges {
  padding: 20px;

  .tip {
    margin-bottom: 2rem;
    font-size: 1.6rem;

    a {
      float: right;
    }
  }

  .date {
    text-align: center;

    .day {
      font-size: 1.8rem;
      font-weight: 500;
      strong{
        font-size: 2rem;
        font-weight: 700;
      }
    }

    .year {
      opacity: .7;
    }
  }
  .item{
    strong{
      float: right;
    }
  }

}

.tipWrap {
  h4 {
    margin: 1rem 0;
    text-align: center;
    font-size: 1.8rem;
  }
}

@primary-color: #1DA57A;