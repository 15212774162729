
.wrapper {
  text-align: center;
  .box {
    .note {
      margin-top: 35px;
      color: #666;
    }
  }
}




@primary-color: #1DA57A;