.cardList {
  .item {
    padding: 1.6rem;

    h4 {
      margin: 0;
      font-size: 1.8rem;
      border-left: .4rem solid red;
      padding-left: 1rem;

      &:global {
        &.yellow {
          color: #FFC803;
          border-color: #FFC803;
        }

        &.green {
          color: green;
          border-color: green;
        }
      }
    }
  }

  .list {
    border: .1rem solid #eee;
    margin-top: 1rem;

    :global {
      .adm-card-header:not(:last-child) {
        border-bottom: 0;
      }

      .adm-card:not(:last-child) {
        border-bottom: 1px solid #eee;
        border-radius: 0;
      }

      .adm-card-body {
        padding-top: 0;
      }
    }
  }

  .numWrap {
    display: flex;
    text-align: center;

    .num {
      flex: 1;

      .label {
        display: inline-block;
        font-size: 1.6rem;
        padding: .2rem .8rem;
        margin-bottom: 1rem;
      }

      .num {
        font-size: 2.2rem;
        padding-top: 1rem;
        font-weight: 500;

        span {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
  }
}

@primary-color: #1DA57A;