.summary {
  border-bottom: .1rem solid #eee;

  .top {
    display: flex;
    padding-top: 1rem;

    .item {
      flex: 1;
      text-align: center;

      .label {
        display: block;
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      .num {
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
}

@primary-color: #1DA57A;