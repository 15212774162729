.me {
  padding: 20px;

  .card {
    border: 1px solid #eee;
    border-radius: 1rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .1), 0 -1px 1px rgba(0, 0, 0, .1);
  }

  .nav {
    margin-top: 2rem;

  }

  h3 {
    padding-top: 40px;
    text-align: center;
    font-size: 2.4rem;
  }
}

@primary-color: #1DA57A;