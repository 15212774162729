.zones {
  .action {
    text-align: right;
    padding: 1.6rem 1.6rem 0 0;
    //position: absolute;
    //right: -0.5rem;
    //top: -0.6rem;
  }

  .card {
    border: 1px solid #eee;
    border-radius: 1rem;
    margin: 1.6rem;

    .content {
      color: #888;

      span {
        margin-right: 2rem;
      }
    }

  }
}


@primary-color: #1DA57A;