.header {
  //padding: 1rem;
  font-size: 1.6rem;
  //height: 2.6rem;
  //border-bottom: 1px solid #eee;

  .zone {
    line-height: 1.5;
  }

  .navLink {
    float: right;
  }

  .menuIcon {
    display: inline-block;
  }

  .navBar {
    border-bottom: 1px solid #eee
  }

  .menu {
    z-index: 100;
    position: fixed;
    right: -12rem;
    top: 4.5rem;
    background-color: #fff;
    //padding-right: 2rem;
    border-left: 1px solid #eee;
    //border-top: 0;
    width: 10.2rem;
    transition: right .3s;
    //box-shadow: 0 2px 3px rgba(0, 0, 0, .1), 0 -1px 1px rgba(0, 0, 0, .1);
    &.show {
      right: 0;
    }

    :global {
      .adm-list-item-content-main {
        text-align: center;
      }
    }
  }
}

@primary-color: #1DA57A;