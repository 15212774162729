.charges {
  padding: 20px;

  .action {
    text-align: right;
    padding-bottom: 1.6rem;
    //padding: 1.6rem 1.6rem 0 0;
    //position: absolute;
    //right: -0.5rem;
    //top: -0.6rem;
  }

  .tip {
    margin-bottom: 2rem;
    font-size: 1.6rem;

    a {
      float: right;
    }
  }

  .date {
    text-align: center;

    .day {
      font-size: 1.8rem;
      font-weight: 500;
    }

    .year {
      opacity: .7;
    }
  }

  .label {
    color: #888;
    margin-left: 1rem;
  }

}

.status {
  padding-top: .5rem;

  span {
    display: inline-block;
    margin-right: 2rem;
    position: relative;
    padding-left: 28px;
    font-size: 1.6rem;
    color: #000;

    &:after {
      content: ' ';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 4px;
    }

    &.red {
      &:after {
        background-color: red;
      }
    }

    &.yellow {
      &:after {
        background-color: #FFC803;
      }
    }

    &.green {
      &:after {
        background-color: green;
      }
    }
  }
}

.tipWrap {
  h4 {
    margin: 1rem 0;
    text-align: center;
    font-size: 1.8rem;
  }
}

@primary-color: #1DA57A;