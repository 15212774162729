.staffs {
  .amountTip {
    padding-top: 1rem;
    line-height: 1.8;
    text-indent: 2rem;
    font-size: 1.6rem;

    a {
      float: right;
      margin-right: 1rem;
    }
  }

  .formWrap {
    padding-top: 1rem;
  }

  .radio {
    margin-right: .8rem;
  }

  .del {
    text-align: center;
    padding-top: 4rem;
    font-size: 1.4rem;
  }

  .password {
    text-align: center;
    padding-bottom: 1.5rem;
    height: 2.2rem;
    font-size: 1.8rem;
  }
}


@primary-color: #1DA57A;