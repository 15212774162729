.card {
  display: flex;
  margin: 1.6rem;
  border: 1px solid #eee;
  text-align: center;

  .item {
    flex: 1;
    padding: 1.5rem 1rem;

    .label {
      background-color: red;
      display: inline-block;
      font-size: 1.6rem;
      padding: .2rem .8rem;
      border-radius: .3rem;
      color: #fff;
    }

    &.yellow {
      .label {
        background-color: #FFC803;
        color: #fff
      }
    }

    &.green {
      .label {
        background-color: green;
      }
    }

    .num {
      font-size: 2.2rem;
      padding-top: 1rem;
      font-weight: 500;

      span {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
}

@primary-color: #1DA57A;