.zones {
  .formWrap {
    padding-top: 4rem;
  }

  .del {
    text-align: center;
    padding-top: 4rem;
    font-size: 1.4rem;
  }
}


@primary-color: #1DA57A;