html, body {
  color: #222;
  font-size: 62.5%;
  min-height: 100vh;
  //background-color: #eee;
}

input.adm-input-element[readonly] {
  pointer-events: all;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }

}

@primary-color: #1DA57A;