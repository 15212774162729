.week {
  background-color: #0E7DB4;

  .current {
    padding-top: 1rem;
    text-indent: 1rem;
    color: #fff;
    font-size: 1.2rem;
    opacity: .7;
  }

  ul {
    display: flex;
    padding: .5rem 0 1rem;

    li {
      flex: 1;
      color: #fff;
      text-align: center;
      font-size: 1.6rem;

      .day {
        opacity: .7;
        display: block;
        margin-bottom: .5rem;
      }

      .date {
        display: inline-block;
        padding: .2rem .4rem;
        cursor: pointer;


      }

      &.active {
        .date {
          background-color: #fff;
          color: #222;
        }
      }

    }
  }

}

@primary-color: #1DA57A;